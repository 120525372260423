<template>
    <div class="about">
        <header class="page-header">
            <div class="container">
                <div class="page-header-flex-wrap no-margin-bottom"><h1 class="page-title section-header">{{ $t("about_us") }}</h1></div>
            </div>
        </header>
        <section class="section medium">
            <div class="container"><div class="container-small" v-html="$t('about_us_text')"></div></div>
        </section>
        <section class="section medium">
            <div class="container">
                <div class="container-small">
                    <h2 class="text-center">{{ $t("contact") }}</h2>
                    <div class="contact-info-block">
                        <div class="contact-info-flex-wrap">
                            <div class="contact-info-content">
                                <img
                                    src="https://assets.website-files.com/5f0763bf575ff70f2f96ccaa/5f0dc6844ceca0bfc12d44e0_explore-white.svg"
                                    alt=""
                                    class="contact-info-icon"
                                />
                                <div class="contact-info-text">
                                    Cyberrit Sp. z o.o.o.<br />ul. Dolnych Młynów 3/1<br />31-124 Krakow<br />Poland
                                </div>
                            </div>
                            <div class="contact-info-content center"></div>
                            <div class="contact-info-content">
                                <img
                                    src="https://assets.website-files.com/5f0763bf575ff70f2f96ccaa/5f0efaf7bfc9742306eff8c5_envelope-white.svg"
                                    alt=""
                                    class="contact-info-icon"
                                />
                                <div class="contact-info-text">
                                    <a href="mailto:support@elzabot.com" class="contact-utility-link">support@elzabot.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                  <!--
                    <div class="contact-form w-form" style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;">
                        <form id="wf-form" name="wf-form">
                            <div class="text-field-flex-wrap">
                                <div class="text-field-wrap small-margin-bottom">
                                    <label for="First_Name_support" class="field-label">{{ $t("first_name") }}</label>
                                    <input
                                        type="text"
                                        class="text-field w-input"
                                        maxlength="256"
                                        name="First_Name_support"
                                        data-name="Sign In First Name"
                                        :placeholder="$t('first_name')"
                                        required=""
                                        v-model="forms.first_name"
                                    />
                                </div>
                                <div class="text-field-spacer"></div>
                                <div class="text-field-wrap small-margin-bottom">
                                    <label for="Last_Name_support" class="field-label">{{ $t("last_name") }}</label>
                                    <input type="text" class="text-field w-input" maxlength="256" name="Last_Name_support"
                                        data-name="Sign In Last Name" :placeholder="$t('last_name')" required=""
                                           v-model="forms.last_name"/>
                                </div>
                            </div>
                            <div class="text-field-wrap">
                                <label for="email_support" class="field-label">{{ $t("email") }}</label>
                                <input
                                    type="email"
                                    class="text-field w-input"
                                    maxlength="256"
                                    name="email"
                                    data-name="Email"
                                    :placeholder="$t('email')"
                                    required=""
                                    v-model="forms.email"
                                />
                            </div>
                            <div class="text-field-wrap">
                                <label
                                    for="title_support"
                                    class="field-label"
                                    >{{ $t("title") }}</label
                                >
                                <input
                                    type="text"
                                    class="text-field w-input"
                                    maxlength="256"
                                    name="title"
                                    data-name="Title"
                                    :placeholder="$t('title')"
                                    required=""
                                    v-model="forms.title"
                                />
                            </div>
                            <div class="text-field-wrap">
                                <label for="Subject_support" class="field-label">{{ $t("subject") }}</label>
                                <select
                                    id="Subject_support"
                                    name="Subject"
                                    data-name="Subject"
                                    required=""
                                    class="text-field select w-select"
                                >
                                  <option v-for="(option, index) in list_department" :key="index" v-bind:value="index">
                                    {{ option.title }}
                                  </option>
                                </select>
                            </div>
                            <div class="text-field-wrap large-margin-bottom">
                                <label for="field" class="field-label">{{
                                    $t("message")
                                }}</label>
                                <textarea
                                    :placeholder="
                                        $t('how_can_we_help_you_today')
                                    "
                                    maxlength="5000"
                                    id="field"
                                    name="field"
                                    data-name="Field"
                                    class="text-field min-height w-input"
                                    v-model="forms.message"
                                ></textarea>
                            </div>
                            <input
                                type="button"
                                v-on:click="sendSupport"
                                :value="$t('submit')"
                                data-wait="Please wait..."
                                class="filled-btn full-width w-button"
                            />
                        </form>
                        <div v-show="forms.done" class="success-message soft"><div>{{forms.messageDone}}</div></div>
                        <div v-show="forms.fail" class="error-message soft"><div>{{forms.messageFail}}</div></div>
                    </div>
                  -->
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from "axios";
import config from "@/store/config";

export default {
  name: "About",
  data() {
    return {
      list_department: [],
      forms : {
        email : "",
        first_name : "",
        last_name : "",
        title : "",
        message : "",
        subject : "",
        done : false,
        fail : false,
        messageDone : "",
        messageFail : ""
      }
    };
  },
  watch: {
    "$store.state.lang": function () {
      this.getListDepartmen();
    },
  },
  mounted() {
    this.getListDepartmen();
  },
  methods: {
    getListDepartmen: function () {
      axios({
        method: "POST",
        url:
            config.getters.mainUrl + "service/listDepartment?ts=" + Math.round(+new Date() / 1000),
        data: {
          lang: this.$cookie.get("elza_lng"),
        },
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }).then(
          result => {
            if (
                result != undefined &&
                result.data != undefined &&
                result.data.result != undefined
            ) {
              if (result.data.result == true) {
                this.list_department = result.data.data;
              }
            }
          },
          error => {
            console.log(error);
          },
      );
    },
    validationForm: function () {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.forms.email)) {
        this.forms.messageFail = this.$t("error_not_valid_email");
        this.forms.fail = true;
        setTimeout(this.closeFailMessage, 2000);
        return false;
      } else if (!/^[a-zA-Z]+$/.test(this.forms.first_name)) {
        this.forms.messageFail = this.$t("error_not_valid_first_name");
        this.forms.fail = true;
        setTimeout(this.closeFailMessage, 2000);
        return false;
      } else if (!/^[a-zA-Z]+$/.test(this.forms.last_name)) {
        this.forms.messageFail = this.$t("error_not_valid_last_name");
        this.forms.fail = true;
        setTimeout(this.closeFailMessage, 2000);
        return false;
      } else if (!/^(?![ -.&,_'":?!])(?!.*[- &_'":]$)(?!.*[-.#@&,:?!]{2})[a-zA-Z0-9- .#@&,_'":.?!]+$/.test(this.forms.title)) {
        this.forms.messageFail = this.$t("error_not_valid_title");
        this.forms.fail = true;
        setTimeout(this.closeFailMessage, 2000);
        return false;
      } else if (!/^[a-zA-Z0-9- .#@&,_'":.?!]+/.test(this.forms.message)) {
        this.forms.messageFail = this.$t("error_not_valid_message");
        this.forms.fail = true;
        setTimeout(this.closeFailMessage, 2000);
        return false;
      }
      return true;
    },
    sendSupport: function () {
      if (this.validationForm()) {
        axios({
          method: "POST",
          url: config.getters.mainUrl + "service/createSupport?ts=" + Math.round(+new Date() / 1000),
          data: {
            title: this.forms.title,
            email: this.forms.email,
            department_id: $("#Subject_support").val(),
            description: this.forms.message,
            first_name: this.forms.first_name,
            last_name: this.forms.last_name,
            lang: this.$cookie.get("elza_lng"),
          },
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        }).then(
            result => {
              if (
                  result != undefined &&
                  result.data != undefined &&
                  result.data.result != undefined
              ) {
                if (result.data.result == true) {
                  this.forms.messageDone = this.$t("thanks_page");
                  this.forms.done = true;
                  setTimeout(this.closeDoneMessage, 2000);
                }
              }
            },
            error => {
              console.log(error);
            },
        );
      }
    },
    closeFailMessage : function () {
      this.forms.messageFail = "";
      this.forms.fail = false;
    },
    closeDoneMessage : function () {
      this.forms.messageDone = "";
      this.forms.done = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.medium + .medium {
    padding-top: 0;
}

h2 {
    margin-bottom: 30px;
}

h5 {
    margin-top: 40px;
}
</style>
